<template>
  <feedbacks-widget
    :can-manage="canManageFeedback"
    :feedbacks="feedbacks"
    :feedbacks-loading="feedbacksLoading"
    :on-view-all="handleViewAll"
    :total-feedback-count="totalFeedbackCount"
  />
</template>

<script>
import apiRoutes from "@/consts/routes/apiRoutes";
import FeedbacksWidget from "@/view/components/Common/widgets/FeedbacksWidget.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FeedbacksWidget,
  },
  data() {
    return {
      feedbacks: [],
      totalFeedbackCount: 0,
      feedbacksLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      getDashboardWidgets: "getDashboardWidgets",
      getAppChartStats: "getAppChartStats",
      getDashboardLoader: "getDashboardLoader"
    }),
    appId() {
      return this.$route.params.app_id;
    },
  },
  watch: {
    getDashboardLoader () {
      this.feedbacks = this.getDashboardWidgets.recentFeedback
      this.totalFeedbackCount = this.getAppChartStats?.meta?.feedbacks
      this.feedbacksLoading = this.getDashboardLoader
    }
  },
  methods: {
    getFeedbacks() {
      this.feedbacksLoading = true;
      const params = new URLSearchParams({
        limit: 5,
      });

      return this.$coreApi
        .get(`${apiRoutes.core.session.allFeedback}?${params.toString()}`)
        .then(({ data }) => {
          this.feedbacks = data.data ?? [];
          this.totalFeedbackCount = data.meta.total;

          return data;
        })
        .catch((error) => {
          this.notifyErrorMessage(error.message);
        })
        .finally(() => {
          this.feedbacksLoading = false;
        });
    },
    handleViewAll() {
      this.$router.push({
        name: "session-feedback",
      });
    },
  },
  created() {
    if (this.canManageFeedback && this.$route.params?.app_id) {
      this.getFeedbacks();
    } else {
      this.feedbacks = this.getDashboardWidgets.recentFeedback
      this.totalFeedbackCount = this.getAppChartStats?.meta?.feedbacks
      this.feedbacksLoading = this.getDashboardLoader
    }
  },
};
</script>

<style lang="scss" module scoped>
.widget {
  padding-left: 0;
  padding-right: 0;
}
</style>
