<template>
  <div>
    <!-- dashboard page -->
    <v-row v-if="getInactiveUserMessage">
      <v-col cols="12">
        <v-alert color="error" outlined>
          {{ getInactiveUserMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="getDashboardLoader">
      <v-col cols="12">
        <v-skeleton-loader type="card, list"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" v-for="col in 4" :key="col">
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!getInactiveUserMessage && !getDashboardLoader">
      <v-col class="mt-3" cols="12">
        <dashboard-stats></dashboard-stats>
      </v-col>
    </v-row>
    <v-row v-if="!getDashboardLoader">
      <v-col cols="12" md="12">
        <recent-releases-widget
          :releases="getDashboardWidgets.recentReleases"
          :total-releases="getAppChartStats?.meta?.releases || 0"
          :dashboard-loader="getDashboardLoader"
          :get-inactive-user-message="getInactiveUserMessage"
        ></recent-releases-widget>
      </v-col>
      <v-col cols="12" md="12">
        <recent-comments-widget
          :comments="getDashboardWidgets.recentComments"
          :total-comments="getAppChartStats?.meta?.release_comments"
          :get-inactive-user-message="getInactiveUserMessage"
          :dashboard-loader="getDashboardLoader"
        />
      </v-col>
      <v-col v-if="canManageApp" cols="12" md="12">
        <recent-sessions-widget />
      </v-col>
      <v-col v-if="canManageFeedback" cols="12" md="12">
        <recent-feedback-widget />
      </v-col>
    </v-row>
    <Modal v-model="phoneUserWarning" hide-close>
      <template #message>
        <div class="my-n3">
          <v-card-title class="font-weight-bold"> Attention</v-card-title>
          <v-divider />
          <v-card-text class="message">
            Portal is best managed in your desktop or laptop browser.
            <br />
            <br />

            From our app, you can install the releases that were created in this
            Portal.
            <br />
            <br />
            If you don't have our app yet,
            <a href="/start?from=attention-popup">click here to install.</a>
          </v-card-text>

          <div class="d-flex justify-center text-center mt-3 px-2">
            <v-checkbox v-model="checkbox">
              <template slot="label">
                <div class="text-body-1">Don't show this message again</div>
              </template>
            </v-checkbox>
          </div>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="main primary" @click="phoneUserWarning = false"
              >Ok, got it!
            </v-btn>
          </v-card-actions>
        </div>
      </template>
    </Modal>
    <Modal
      v-model="getStartedVideo"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      hide-close
      width="700"
    >
      <template #card>
        <video-modal
          :video-class="`wistia_embed wistia_async_1r6jns5l8i email=${user?.id}`"
          first-message="TestApp.io was built by developers to solve the pain of app
                distribution 💪"
          second-message="Check out our walkthrough video to get the full advantage of
                TestApp.io"
          title="Welcome aboard!"
          @close="setWelcomeVideoPopup"
        ></video-modal>
      </template>
    </Modal>
  </div>
</template>

<script>
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import { DASHBOARD } from "@/store/users/dashboard.module";
import VideoModal from "@/view/components/Common/VideoModal";
import StorageService from "@/core/services/localstorage.service";
import RecentFeedbackWidget from "@/view/pages/user/dashboard/widgets/RecentFeedbackWidget.vue";
import RecentSessionsWidget from "@/view/pages/user/dashboard/widgets/RecentSessionsWidget.vue";
import DashboardStats from "@/view/components/User/DashboardStats";
import RecentReleasesWidget from "@/view/pages/user/dashboard/widgets/RecentReleasesWidget";
import RecentCommentsWidget from "@/view/pages/user/dashboard/widgets/RecentCommentsWidget";
import { GET_DASHBORD_APP_CHART_STATS } from "@/store/apps/app.module.js";
// import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";

export default {
  name: "dashboard",
  data() {
    return {
      get,
      formatBytes,
      attrs: {},
      step: 1,
      getStartedVideo: false,

      feedbackHeader: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          width: "150",
          value: "name",
        },
        {
          text: "App",
          align: "start",
          sortable: false,
          width: "200",
          value: "app",
        },
        {
          text: "Attachments",
          align: "start",
          sortable: false,
          width: "100",
          value: "location",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          width: "50",
          value: "action",
        },
      ],
      breadCrumbs: [
        { text: "Dashboard", disabled: false, to: { name: "dashboard" } },
      ],
      phoneUserWarning: false,
      checkbox: false,
      d: "2023-07-15T14:12:23+00:00",
    };
  },
  components: {
    RecentCommentsWidget,
    RecentReleasesWidget,
    DashboardStats,
    VideoModal,
    RecentFeedbackWidget,
    RecentSessionsWidget,
  },
  metaInfo: {
    title: "TestApp.io - Portal",
    link: [{ rel: "canonical", href: "https://portal.testapp.io/" }],
    meta: [
      {
        name: "og:description",
        content:
          "A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!",
      },
      {
        name: "twitter:description",
        content:
          "A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!",
      },
      // {
      //   name: "robots",
      //   content: "noindex,nofollow"
      // }
    ],
  },
  watch: {
    checkbox(val) {
      localStorage.setItem(
        "popup",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("popup")),
          phoneUserWarning: val.toString(),
        })
      );
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      usage: (state) => get(state, "dashboard.usage", ""),
      constants: (state) => state.util.constants,
      currentPlanUsage: (state) => state.dashboard.currentPlanUsage,
    }),
    ...mapGetters({
      userPermissions: "userPermissions",
      getTeamId: "getTeamId",
      getDarkTheme: "getDarkTheme",
      getKycFormStatus: "getKycFormStatus",
      getAppChartStats: "getAppChartStats",
      getDashboardWidgets: "getDashboardWidgets",
      getInactiveUserMessage: "getInactiveUserMessage",
      getDashboardLoader: "getDashboardLoader",
    }),
    noApps() {
      return isEmpty(this.apps) ? true : false;
    },
    noReleases() {
      return isEmpty(this.releases) ? true : false;
    },
    dashboardStats() {
      return [
        {
          title: "Total Apps",
          icon: "mdi-apps",
          color: "info",
          count: this.st,
        },
      ];
    },
  },
  created() {
    this.viewWelcomeVideo();
    this.fetchAppChartStats();
  },
  mounted() {
    this.setAuthMessage({ message: "", type: "" });
    if (!this.getInactiveUserMessage) {
      this.$store
        .dispatch(DASHBOARD)
        .then(() => {
          if (StorageService.getItem("dark-theme")) {
            this.$vuetify.theme.dark = true;
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    }
    const popup = JSON.parse(localStorage.getItem("popup"));
    if (this.getMobileOperatingSystem !== "unknown") {
      if (popup && popup.phoneUserWarning) {
        popup.phoneUserWarning === "true"
          ? (this.phoneUserWarning = false)
          : (this.phoneUserWarning = true);
      } else {
        this.phoneUserWarning = true;
      }
    }
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    fetchAppChartStats() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_DASHBORD_APP_CHART_STATS, {
          id: this.$route.params.app_id,
        })
        .then(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
          this.loading = false;
        });
    },
    navigateToApp(name, id) {
      this.$router
        .push({
          name: name,
          params: {
            app_id: id,
          },
        })
        .catch(() => {});
    },
    setWelcomeVideoPopup() {
      this.getStartedVideo = false;
      StorageService.setItem("video", {
        release: StorageService.getItem("video")
          ? StorageService.getItem("video").release || false
          : false,
        share: StorageService.getItem("video")
          ? StorageService.getItem("video").share || false
          : false,
        welcome: true,
      });
    },
    viewWelcomeVideo() {
      const video = StorageService.getItem("video");
      if (!video || !video.welcome) {
        this.getStartedVideo = true;
      } else if (video && video.welcome === "false") {
        this.getStartedVideo = true;
      }
    },
    goToManageTeamMembers() {
      this.$router.push({ name: "team-members" }).catch(() => {});
    },
    goToCreateApp() {
      this.$router.push({ name: "createapp" }).catch(() => {});
    },
    replaceDefaultReleaseImage(e) {
      e.target.src = "/media/defaults/icon-50.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-muted {
  font-weight: 600;
}

.font-size-lg,
.text-muted {
  font-size: 13px !important;
}

.platforms {
  font-size: 16px !important;
}
</style>
