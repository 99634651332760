<template>
  <div>
    <group-status-card
      outlined
      dot-type="icon"
      :stats="dashboardStats.filter((stat) => !!stat.permissions)"
    ></group-status-card>
  </div>
</template>

<script>
import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";
import { formatBytes } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
export default {
  components: { GroupStatusCard },
  computed: {
    ...mapGetters({
      getAppChartStats: "getAppChartStats",
    }),
    dashboardStats() {
      return [
        {
          color: "info",
          icon: "mdi-apps",
          title: "Apps",
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.apps || 0
          ),
          permissions: true,
          value: "apps",
          link: "/apps",
          info: "Your active apps in this team",
        },
        {
          color: "info",
          icon: "mdi-account-group",
          title: "Members",
          permissions: this.canViewTeamMembers,
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.members || 0
          ),
          value: "members",
          link: "/team/members",
          info: "Your active apps in this team",
        },
        {
          color: "success",
          icon: "mdi-cellphone-text",
          title: "Releases",
          permissions: true,
          value: "releases",
          link: "/apps?to=app-release-index",
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.releases || 0
          ),
          info: "Your active apps in this team",
        },
        {
          color: "success",
          icon: "mdi-comment-multiple-outline",
          title: "Comments",
          link: "/apps?to=app-comments",
          permissions: this.canManageRelease,
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.release_comments || 0
          ),
          value: "releases_comments",
          info: "Your active apps in this team",
        },
        {
          color: "info",
          icon: "mdi-timeline-text-outline",
          title: "Sessions",
          permissions: this.canManageApp,
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.sessions || 0
          ),
          value: "sessions",
          link: "/apps?to=app-sessions",
          info: "Your active apps in this team",
        },
        {
          color: "deep-purple lighten-2",
          icon: "mdi-cellphone-text",
          title: "Feedback",
          permissions: this.canManageApp,
          count: this.$options?.filters?.formatNumber(
            this.getAppChartStats?.meta?.feedback || 0
          ),
          value: "feedback",
          info: "Your active apps in this team",
        },
        // {
        //   color: "info",
        //   icon: "mdi-database",
        //   title: "Storage",
        //   permissions: this.canManageTeam,
        //   count: formatBytes(this.getAppChartStats?.meta?.storage || 0),
        //   value: 'storage',
        //   info: "Your active apps in this team",
        // },
      ];
    },
  },
};
</script>

<style scoped></style>
