var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('group-status-card', {
    attrs: {
      "outlined": "",
      "dot-type": "icon",
      "stats": _vm.dashboardStats.filter(function (stat) {
        return !!stat.permissions;
      })
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }