var render = function render(){
  var _vm$getAppChartStats, _vm$getAppChartStats$, _vm$getAppChartStats2, _vm$getAppChartStats3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInactiveUserMessage ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "color": "error",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.getInactiveUserMessage) + " ")])], 1)], 1) : _vm._e(), _vm.getDashboardLoader ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card, list"
    }
  })], 1), _vm._l(4, function (col) {
    return _c('v-col', {
      key: col,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "table"
      }
    })], 1);
  })], 2) : _vm._e(), !_vm.getInactiveUserMessage && !_vm.getDashboardLoader ? _c('v-row', [_c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('dashboard-stats')], 1)], 1) : _vm._e(), !_vm.getDashboardLoader ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-releases-widget', {
    attrs: {
      "releases": _vm.getDashboardWidgets.recentReleases,
      "total-releases": ((_vm$getAppChartStats = _vm.getAppChartStats) === null || _vm$getAppChartStats === void 0 ? void 0 : (_vm$getAppChartStats$ = _vm$getAppChartStats.meta) === null || _vm$getAppChartStats$ === void 0 ? void 0 : _vm$getAppChartStats$.releases) || 0,
      "dashboard-loader": _vm.getDashboardLoader,
      "get-inactive-user-message": _vm.getInactiveUserMessage
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-comments-widget', {
    attrs: {
      "comments": _vm.getDashboardWidgets.recentComments,
      "total-comments": (_vm$getAppChartStats2 = _vm.getAppChartStats) === null || _vm$getAppChartStats2 === void 0 ? void 0 : (_vm$getAppChartStats3 = _vm$getAppChartStats2.meta) === null || _vm$getAppChartStats3 === void 0 ? void 0 : _vm$getAppChartStats3.release_comments,
      "get-inactive-user-message": _vm.getInactiveUserMessage,
      "dashboard-loader": _vm.getDashboardLoader
    }
  })], 1), _vm.canManageApp ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-sessions-widget')], 1) : _vm._e(), _vm.canManageFeedback ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('recent-feedback-widget')], 1) : _vm._e()], 1) : _vm._e(), _c('Modal', {
    attrs: {
      "hide-close": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('div', {
          staticClass: "my-n3"
        }, [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" Attention")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "message"
        }, [_vm._v(" Portal is best managed in your desktop or laptop browser. "), _c('br'), _c('br'), _vm._v(" From our app, you can install the releases that were created in this Portal. "), _c('br'), _c('br'), _vm._v(" If you don't have our app yet, "), _c('a', {
          attrs: {
            "href": "/start?from=attention-popup"
          }
        }, [_vm._v("click here to install.")])]), _c('div', {
          staticClass: "d-flex justify-center text-center mt-3 px-2"
        }, [_c('v-checkbox', {
          model: {
            value: _vm.checkbox,
            callback: function callback($$v) {
              _vm.checkbox = $$v;
            },
            expression: "checkbox"
          }
        }, [_c('template', {
          slot: "label"
        }, [_c('div', {
          staticClass: "text-body-1"
        }, [_vm._v("Don't show this message again")])])], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "main primary",
          on: {
            "click": function click($event) {
              _vm.phoneUserWarning = false;
            }
          }
        }, [_vm._v("Ok, got it! ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.phoneUserWarning,
      callback: function callback($$v) {
        _vm.phoneUserWarning = $$v;
      },
      expression: "phoneUserWarning"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "hide-close": "",
      "width": "700"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$user;
        return [_c('video-modal', {
          attrs: {
            "video-class": "wistia_embed wistia_async_1r6jns5l8i email=".concat((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.id),
            "first-message": "TestApp.io was built by developers to solve the pain of app\n              distribution 💪",
            "second-message": "Check out our walkthrough video to get the full advantage of\n              TestApp.io",
            "title": "Welcome aboard!"
          },
          on: {
            "close": _vm.setWelcomeVideoPopup
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.getStartedVideo,
      callback: function callback($$v) {
        _vm.getStartedVideo = $$v;
      },
      expression: "getStartedVideo"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }