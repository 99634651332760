<template>
  <sessions-widget
    :can-manage="canManageApp"
    :on-view-all="handleViewAll"
    :sessions="sessions"
    :sessions-loading="sessionsLoading"
    :total-sessions-count="totalSessionsCount"
  />
</template>

<script>
import SessionsWidget from "@/view/components/Common/widgets/SessionsWidget.vue";
import { mapGetters } from "vuex";

export default {
  components: { SessionsWidget },
  data() {
    return {
      sessions: [],
      totalSessionsCount: 0,
      sessionsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      getAppChartStats: "getAppChartStats",
      getDashboardWidgets: "getDashboardWidgets",
      getDashboardLoader: "getDashboardLoader"
    }),
    appId() {
      return this.$route.params.app_id;
    },
  },
  watch: {
    getDashboardLoader(val) {
      this.sessions = this.getDashboardWidgets.recentSessions
      this.totalSessionsCount = this.getAppChartStats?.meta?.sessions
      this.sessionsLoading = val
    }
  },
  methods: {
    getSessions() {
      this.sessionsLoading = true;
      const params = new URLSearchParams({
        limit: 5,
      });

      return this.$coreApi
        .get(`${this.apiRoutes.core.session.list}?${params.toString()}`)
        .then(({ data }) => {
          this.sessions = data.data ?? [];
          this.totalSessionsCount = data.meta.total;

          return data;
        })
        .catch((error) => {
          this.notifyErrorMessage(error.message);
        })
        .finally(() => {
          this.sessionsLoading = false;
        });
    },
    handleViewAll() {
      this.$router.push({
        name: "sessions",
      });
    },
  },
  created() {
    if(this.$route.params.app_id) {
      this.getSessions();
    } else {
      this.sessions = this.getDashboardWidgets.recentSessions
      this.totalSessionsCount = this.getAppChartStats?.meta?.sessions
      this.sessionsLoading = this.getDashboardLoader
    }
  },
};
</script>

<style lang="scss" scoped></style>
